import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {StaticImage,GatsbyImage } from 'gatsby-plugin-image'
import IngredientCard from './IngredientCard'
import { Autocomplete, TextField } from '@mui/material'
import { PrismicRichText } from '@prismicio/react'


const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
`
const Wrap = styled.div`
        max-width:748px;
        margin:0 auto;
        .firstImg {
            margin-top: 5rem;
        }
        @media screen and (max-width: 600px) {
            max-width:85vw; 
        }
`
const Title = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
    @media screen and (max-width: 500px) {
        font-size: 32px;
        line-height: 1.5;
    }
`
const Paragraph = styled.div`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #424242;
    margin-bottom:41px;
    @media screen and (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`

const IngredientContainer = styled.div`
  padding:32px 0;
  display:flex;
  flex-wrap:wrap;
  gap:16px

`
const NavBar = styled.nav`
  display:flex;
  gap:16px;
  padding:12px 0;
  margin:32px 0;
  overflow-x:scroll;
  align-items:center;
  justify-content:space-between;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */

`
const NavItem = styled.li`
    padding: 8px;
    border-radius: 12px;
    border: solid 1.5px #112A46;;
    cursor:pointer;
    color: #112A46;
    font-size: 28px;
    line-height: 33px;
    white-space: nowrap;
    font-family: 'SF Pro Rounded';
    
    &:hover{
        background-color: #BBC4CE;
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 20px;
    }
  
`
const CustomInput = styled(Autocomplete)`
    .MuiInputBase-root {
        background-color: white;
        border-radius:8px;
        font-family: 'SF Pro Rounded';
    }
    .MuiFormLabel-root{
        color:#000;
        font-family: 'SF Pro Rounded';
    }
    .Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: #112A46 !important
    }
`
const CustomTextField = styled(TextField)`
    &::placeholder{
        color:#000
    }

`
const EmptyDiv=styled.div`
    display:grid;
    place-items:center;
    padding:80px 0;

`
const NavItemImg = styled.li`
    background: #FFFFFF;
    border: solid 1px  rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px ;
    border-radius: 16px;
    cursor:pointer;
    width:fit-content;
    display:flex;
    overflow:hidden;
    gap:12px;
    align-items:center;
    height:90px;
    min-width:fit-content;
`
const Text = styled.div`
    display:flex;
    gap:8px;
    font-family: 'SF Pro Rounded';
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-left:20px;
    align-items:center
`
interface RecipeMainProps {
    lang : string
    recipe : any
}

const RecipeMain: React.FC<RecipeMainProps> = ({lang,recipe}) => {
        const [ingredientType,setIngredientType]=useState('all')
        const [ingredientsList,setIngredientsList]=useState(recipe.data.ingredients)
        const [displayedIngredients,setDisplayedIngredients]=useState(ingredientsList)
        const [searchQuery,setSearchQuery]=useState("")

        useEffect(()=>{
            if(ingredientType==='all'){
                setIngredientsList(recipe.data.ingredients)
                setDisplayedIngredients(recipe.data.ingredients)
            }
            else{
                setIngredientsList(recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === ingredientType))
                setDisplayedIngredients(recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === ingredientType))
            }
        },[ingredientType])
        useEffect(()=>{
            if(searchQuery !=='') {
                setDisplayedIngredients(ingredientsList.filter(({ingredient}:any)=> ingredient.document.data.title.toLowerCase().match(searchQuery.toLocaleLowerCase())))
            }
            else {
                setIngredientsList(recipe.data.ingredients)
                setDisplayedIngredients(recipe.data.ingredients)
            }
        },[searchQuery])

        return (

            <Wrap>
                <Title className="slow">
                    {recipe.data.title.text}   
                </Title>
                <Paragraph>
                    <PrismicRichText field={recipe.data.description.richText}/>
                </Paragraph>   
                <ImgWrapper>
                    <GatsbyImage image={recipe.data.image.gatsbyImageData} alt='main'/>
                </ImgWrapper>
                <NavBar>
                    <NavItem className={ingredientType==='all'?'active-nav':''} onClick={()=>{setIngredientType('all')}}>All</NavItem>
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'organic').length>0 && <NavItemImg onClick={()=>{setIngredientType('organic')}}>
                        <Text>
                            <p>🌿</p>
                            {lang==="en-us" ? <p>Organic</p>:<p>Bio</p>}
                        </Text>
                        <StaticImage src='../images/organic.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'fruits').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('fruits')}}>
                        <Text>
                            <p>🍍</p>
                            {lang==="en-us" ? <p>Fruits &<br/>vegetables</p>:<p>Fruits et<br/>légumes</p>}
                        </Text>
                        <StaticImage src='../images/fruits.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'halal-meat').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('halal-meat')}}>
                        <Text>
                            <p>✅</p>
                            {lang==="en-us" ? <p>Halal Meat</p>:<p>Viande Halal</p>}
                        </Text>
                        <StaticImage src='../images/halaMeat.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'haram-meat').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('haram-meat')}} style={{background: '#FFD8D8'}}>
                        <Text style={{color: '#D94C4C'}}>
                            <p>❌</p>
                            {lang==="en-us" ? <p>Non-Halal<br/> Meat</p>:<p>Viande non<br/> Halal</p>}
                        </Text>
                        <StaticImage src='../images/haramMeat.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'fish').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('fish')}}>
                        <Text>
                            <p>🎣</p>
                            {lang==="en-us" ? <p>Fish</p>:<p>Poissons</p>}
                        </Text>
                        <StaticImage src='../images/fish.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'breads').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('breads')}}>
                        <Text>
                            <p>🥖</p>
                            {lang==="en-us" ? <p>Breads</p>:<p>Pains</p>}
                        </Text>
                        <StaticImage src='../images/breads.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'bakery').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('bakery')}}>
                        <Text>
                            <p>🍰</p>
                            {lang==="en-us" ? <p>Bakery</p>:<p>Pâtisseries</p>}
                        </Text>
                        <StaticImage src='../images/bakery.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'fresh-food').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('fresh-food')}}>
                        <Text>
                            <p>🍍</p>
                            {lang==="en-us" ? <p>Fresh Food</p>:<p>Fresh Food</p>}
                        </Text>
                        <StaticImage src='../images/freshFood.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'frozen').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('frozen')}}>
                        <Text>
                            <p>❄️</p>
                            {lang==="en-us" ? <p>Frozen</p>:<p>Surgelés</p>}
                        </Text>
                        <StaticImage src='../images/frozen.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'pizzas').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('pizzas')}}>
                        <Text>
                            <p>🍕</p>
                            {lang==="en-us" ? <p>Pizzas</p>:<p>Pizzas</p>}
                        </Text>
                        <StaticImage src='../images/pizzas.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'ice-cream').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('ice-cream')}}>
                        <Text>
                            <p>🍦</p>
                            {lang==="en-us" ? <p>Ice cream</p>:<p>Glaces</p>}
                        </Text>
                        <StaticImage src='../images/iceCream.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'drinks').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('drinks')}}>
                        <Text>
                            <p>🚰</p>
                            {lang==="en-us" ? <p>Drinks</p>:<p>Boissons</p>}
                        </Text>
                        <StaticImage src='../images/drinks.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'alcoholic-beverages').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('alcoholic-beverages')}}style={{background: '#FFD8D8'}}>
                        <Text style={{color: '#D94C4C'}}>
                            <p>🍷 </p>
                            {lang==="en-us" ? <p>Alcoholic<br/>beverages</p>:<p>Boissons<br/>alcoolisées</p>}
                        </Text>
                        <StaticImage src='../images/alcohol.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'yoghurts').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('yoghurts')}}>
                        <Text>
                            <p>🥄</p>
                            {lang==="en-us" ? <p>Yoghurts</p>:<p>Yaourts</p>}
                        </Text>
                        <StaticImage src='../images/yoghurts.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'world-foods').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('world-foods')}}>
                        <Text>
                            <p>🗺️</p>
                            {lang==="en-us" ? <p>World Foods</p>:<p>Produits<br/>du monde</p>}
                        </Text>
                        <StaticImage src='../images/world.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'baby-toddler').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('baby-toddler')}}>
                        <Text>
                            <p>🍼</p>
                            {lang==="en-us" ? <p>Baby & Toddler</p>:<p>Bébé et<br/>tout-petit</p>}
                        </Text>
                        <StaticImage src='../images/baby.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'on-the-go-drinks').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('on-the-go-drinks')}}>
                        <Text>
                            <p>✌️</p>
                            {lang==="en-us" ? <p>On The Go<br/>Drinks</p>:<p>Boissons<br/>à emporter</p>}
                        </Text>
                        <StaticImage src='../images/go-drinks.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'food-cupboard').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('food-cupboard')}}>
                        <Text>
                            <p>📦</p>
                            {lang==="en-us" ? <p>Food Cupboard</p>:<p>Dans le placard</p>}
                        </Text>
                        <StaticImage src='../images/placard.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'cheese-with-animal-rennet').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('cheese-with-animal-rennet')}} style={{background: '#FFD8D8'}}>
                        <Text style={{color: '#D94C4C'}}>
                            <p>🧀</p>
                            {lang==="en-us" ? <p>Cheese with<br/>animal rennet</p>:<p>Fromages avec<br/>présure animale</p>}
                        </Text>
                        <StaticImage src='../images/cheese.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'cheese-without-animal-rennet').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('cheese-without-animal-rennet')}}>
                        <Text>
                            <p>🧀</p>
                            {lang==="en-us" ? <p>Cheese without<br/>animal rennet</p>:<p>Fromages avec<br/>présure animale</p>}
                        </Text>
                        <StaticImage src='../images/cheese1.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'ready-meals').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('ready-meals')}}>
                        <Text>
                            <p>🍲</p>
                            {lang==="en-us" ? <p>Ready Meals</p>:<p>Plats préparés</p>}
                        </Text>
                        <StaticImage src='../images/meal.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'desserts').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('desserts')}}>
                        <Text>
                            <p>🍮</p>
                            {lang==="en-us" ? <p>Desserts</p>:<p>Desserts</p>}
                        </Text>
                        <StaticImage src='../images/desserts.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'juice').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('juice')}}>
                        <Text>
                            <p>🥝</p>
                            {lang==="en-us" ? <p>Juice<br/>& Smooties</p>:<p>Jus<br/>& Smooties</p>}
                        </Text>
                        <StaticImage src='../images/juice.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'sandwichs').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('sandwichs')}}>
                        <Text>
                            <p>🥙</p>
                            {lang==="en-us" ? <p>Sandwichs</p>:<p>Sandwichs</p>}
                        </Text>
                        <StaticImage src='../images/sandwish.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'water').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('water')}}>
                        <Text>
                            <p>🚰</p>
                            {lang==="en-us" ? <p>Water</p>:<p>Eau</p>}
                        </Text>
                        <StaticImage src='../images/water.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'hot-drinks').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('hot-drinks')}}>
                        <Text>
                            <p>🍵</p>
                            {lang==="en-us" ? <p>Tea, coffee<br/>& hot drinks</p>:<p>Thé, café &<br/>boissons<br/>chaudes</p>}
                        </Text>
                        <StaticImage src='../images/tea.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'coffee').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('coffee')}}>
                        <Text>
                            <p>☕</p>
                            {lang==="en-us" ? <p>Coffee</p>:<p>Café</p>}
                        </Text>
                        <StaticImage src='../images/coffee.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'vegetarian').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('vegetarian')}}>
                        <Text>
                            <p>🥗</p>
                            {lang==="en-us" ? <p>Vegetarian,<br/>Vegan &<br/>Plant based</p>:<p>Végétarien,<br/>Vegan et à<br/>base de plantes</p>}
                        </Text>
                        <StaticImage src='../images/vegan.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'dietary').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('dietary')}}>
                        <Text>
                            <p>🥊</p>
                            {lang==="en-us" ? <p>Dietary</p>:<p>Diététique</p>}
                        </Text>
                        <StaticImage src='../images/dietary.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'eggs').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('eggs')}}>
                        <Text>
                            <p>🥚</p>
                            {lang==="en-us" ? <p>Dairy, egg<br/>& chilled</p>:<p>Produits laitiers,<br/>œufs et<br/>réfrigérés</p>}
                        </Text>
                        <StaticImage src='../images/eggs.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                    { recipe.data.ingredients.filter(({ingredient}:any) => ingredient.document.data.tag.toLowerCase() === 'pork').length>0 && 
                    <NavItemImg onClick={()=>{setIngredientType('pork')}}style={{background: '#FFD8D8'}}>
                        <Text style={{color: '#D94C4C'}}>
                            <p>🐷</p>
                            {lang==="en-us" ? <p>Contains pork</p>:<p>Contient<br/>du porc</p>}
                        </Text>
                        <StaticImage src='../images/pork.png' alt='' width={171} height ={114} style={{borderRadius:'10% / 50%',marginRight:'-8px'}}/>
                    </NavItemImg>}
                </NavBar>
                <CustomInput
                    freeSolo
                    id="search-input"
                    disableClearable
                    options= {ingredientsList.map(({ingredient}:any) => ingredient?.document?.data?.title)}
                    noOptionsText="No ingredients with this name"
                    onInputChange={(event, value) => {{
                        if (event?.type === "change" || event?.type === "click" ) {
                            setSearchQuery(value)
                        }
                       }}}
                    renderInput={(params) => (
                    <CustomTextField
                        {...params}
                        label={lang==='fr-fr' ? "Rechercher un ingrédient ...":"Search an ingredient ..."}
                        InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        }}
                        InputLabelProps={{
                            style: { color: '#112A46',fontFamily: 'SF Pro Rounded' },
                        }}
                    />
                    )}
                />
                {displayedIngredients.length>0?
                    <IngredientContainer>
                        {displayedIngredients.map(({ingredient}:any)=>{
                            return <IngredientCard key={ingredient.document.id} label={ingredient.document.data.label} tag={ingredient.document.data.tag}   title={ingredient.document.data.title} type={ingredient.document.data.type} image={ingredient.document.data.image} description_title={ingredient.document.data.description_title} description={ingredient.document.data.description}/>
                        })}
                    </IngredientContainer>
                    :
                    <EmptyDiv>
                        {lang === "en-us" ?
                            <p>There are no ingredients</p>
                        :
                            <p>Il n'y a pas d'ingrédients</p>
                        }
                    </EmptyDiv>
                }
                <Paragraph>
                    {lang==="fr-fr"? 'Préparation :':'Preparation :'}
                    <PrismicRichText field={recipe.data.preparation.richText}/>
                </Paragraph>
            </Wrap>
        );
}
export default RecipeMain