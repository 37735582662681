import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import RecipeMain from '../components/RecipeMain';
import { Helmet } from 'react-helmet';


interface recipeProps {
    data: any
    pageContext:any
}   

const recipe: React.FC<recipeProps> = ({data,pageContext}) => {
        const recipeData=data.prismicRecipe
        return (
          <Layout border='2px solid #424242' lang={pageContext.lang}>
          <Helmet>
            <html lang={pageContext.lang} dir="ltr" />
            <meta charSet="utf-8" key="charset"/>
            <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'/>
            <title>{pageContext.title}</title>
            <meta name="twitter:title" content={pageContext.title}/>
            <meta property="og:title" content={pageContext.title}/>
            <meta name="description" content={pageContext.description}/>
            <meta name="twitter:description" content={pageContext.description}/>
            <meta property="og:description" content={pageContext.description}/>
            <meta property="og:type" content="website"/>
            <meta name="twitter:site" content="@shukran"/>
            <meta name="twitter:creator" content="@BCL"/>
            <meta property="og:site_name" content="Shukran"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:image" content="https://images.prismic.io/shukran/51700c4f-b51e-468f-83e4-c751e6fee2d5_Social-Sharing-image-preview.jpg"/>
            <meta property="og:image" content="https://images.prismic.io/shukran/51700c4f-b51e-468f-83e4-c751e6fee2d5_Social-Sharing-image-preview.jpg"/>
          </Helmet>
            <RecipeMain lang={pageContext.lang} recipe={recipeData}/>
          </Layout>
        );
}
export const query = graphql`
query Query($id : String) {
    prismicRecipe(id: {eq: $id}) {
      lang
      uid
      data {
        title {
          text
        }
        description{
          richText
        }
        image {
          gatsbyImageData
        }
        preparation{
          richText
        }
        ingredients {
          ingredient {
            document {
              ... on PrismicIngredient {
                id
                data {
                  description
                  description_title
                  image {
                    gatsbyImageData
                  }
                  label
                  title
                  type
                  tag
                }
              }
            }
          }
        }
      }
    }
}
`
export default recipe