import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
const Haram = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:12px;
    padding: 16px;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
`
const HaramBtn = styled.span`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #FFD8D8;
    border-radius: 8px;
    color: #5D1414;

`
const HaramTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #18181B;
    margin:0;

`
const HaramaDescription = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin:0;

`
const HaramBtnTwo = styled.span`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    /* Shukran Rechta DS/Haram/Color 2 */
    background: #FFD8D8;
    border-radius: 12px;

`
const HaramBtnTwoTitle = styled.h1`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #5D1414;
    margin:0;

`
const HaramBtnTwoP = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #5D1414;
    margin:0;
`
const Halal = styled.div`
  width:203.66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap:12px;
  background: #FFFFFF;
  border: 1.5px solid #E0E0E0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  @media screen and (max-width: 600px) {
    width:100%
  }
`
const HalalBtn = styled.span`
font-family: 'SF Pro Rounded';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 33px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 8px;
/* Shukran Rechta DS/Halal/Color 2 */

background: #D8FFDE;

border-radius: 8px;
/* Shukran Rechta DS/Haram/Color 1 */

color: #0E4317;
`
const HalalTitle = styled.h1`
    font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* gray/900 */
  width: 100%;
  color: #18181B;
  margin:0;

`
const HalalDescription = styled.p`
    font-family: 'SF Pro Rounded';
    font-style: normal;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin:0;
`
const HalalBtnTwo = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #D8FFDE;
  border-radius: 12px;
  margin:0;
`
const HalalBtnTwoTitle = styled.h1`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #0E4317;
  margin:0;

`
const HalalBtnTwoP = styled.p`
  font-family: 'SF Pro Rounded';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #0E4317;
  margin:0;
`
const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  place-content: center;
`

interface IngredientCardProps {
    tag: string
    label: string
    title: string
    type: string
    image: any
    description_title: string
    description: string
}

const IngredientCard: React.FC<IngredientCardProps> = ({label,title,type,image,description_title,description,tag}) => {

        return (
            <Halal>
              {label==="Halal"?
              
            <HalalBtn>
                {label}
            </HalalBtn>
            :
            <HaramBtn>
                {label}
            </HaramBtn>
            }
            <HalalTitle>
                {title}                   
            </HalalTitle>
            <HalalDescription>
                {type}
            </HalalDescription>
            <ImgWrapper>
            <div>
              <GatsbyImage image={image.gatsbyImageData} alt='img' />
            </div>
            </ImgWrapper>
            {label==="Halal"?
            <HalalBtnTwo>
                <HalalBtnTwoTitle>
                  {description_title}
                </HalalBtnTwoTitle>
                <HalalBtnTwoP>
                    {description}                        
                </HalalBtnTwoP>
            </HalalBtnTwo>
            :
            <HaramBtnTwo>
                <HaramBtnTwoTitle>
                  {description_title}
                </HaramBtnTwoTitle>
                <HaramBtnTwoP>
                    {description}                        
                </HaramBtnTwoP>
            </HaramBtnTwo>
            }
        </Halal>

        );
}
export default IngredientCard